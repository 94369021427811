<template>
  <div>
    <p>Logging out...</p>
  </div>
</template>

<script>
import workflow from '../common/workflow'

export default {
  name: 'logout',
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
      workflow.removeAllLocks()

      let uids = Object.keys(this.$store.state.reportWindows)
      for (let k = 0; k < uids.length; k++) {
        let uid = uids[k]
        try {
          if (this.$store.state.reportWindows[uid] !== undefined) {
            this.$store.state.reportWindows[uid].close()
            this.$store.commit('changeReportWindows', {
              'window': null,
              'windowUid': uid
            })
          }
        }
        catch(err) {
          this.$log.error(`Failed to close report window windowUid=${uid}: ${err.message}`);
        }
      }

      uids = Object.keys(this.$store.state.viewerWindows)
      for (let k = 0; k < uids.length; k++) {
        let uid = uids[k]
        try {
          if (this.$store.state.viewerWindows[uid] !== undefined) {
            this.$store.state.viewerWindows[uid].close()
            this.$store.commit('changeViewerWindows', {
              'window': null,
              'windowUid': uid
            })
          }
        }
        catch(err) {
          this.$log.error(`Failed to close viewer window windowUid=${uid}: ${err.message}`);
        }
      }

      try {
        // +TODO+ Handle flushing OHIF viewer token when logging out.
        //
        var redirectUrl = window.location.origin
        this.$log.info(`Handling logout redirectUrl=${redirectUrl}`);
        var keycloakOptions = {
          "redirectUri": redirectUrl
        }
        this.$store.state.keycloak.logout(keycloakOptions)
      }
      catch(err) {
        this.$log.error('Failed to logout: '+err.message);
      }
  },
  methods: {
  }
};
</script>
<style scoped>
</style>